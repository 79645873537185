import { Breadcrumb, Typography } from "antd"
import { Content } from "antd/lib/layout/layout"
import { Link } from "gatsby"
import React from "react"
import Payment from "./Payment"

const { Title } = Typography

const PaymentManagement = () => {
  return (
    <Content>
      <div
        style={{
          backgroundColor: "#FFFFFF",
          padding: "10px",
        }}
      >
        <Breadcrumb separator={">"}>
          <Breadcrumb.Item>
            <Link to="/home">HOME</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link>販売管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item route="/payment-management">
            <span style={{ cursor: "pointer" }}>支払管理</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Title
          level={3}
          style={{
            margin: "36px 0",
            fontSize: 24,
            fontWeight: 500,
          }}
        >
          支払管理
        </Title>
        <Payment />
      </div>
    </Content>
  )
}

export default PaymentManagement
